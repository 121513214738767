import React from "react";
import styled from "styled-components";
import blogImage from "../images/crowd1.jpg";

const StyledNewsItem = styled.section`
  background-color: var(--background-color2);
`;

const NewsSingle2 = () => {
  return (
    <StyledNewsItem className="causes-detail-area news-detail-area">
      <div className="container">
        <div className="row blog-content-wrap justify-content-center">
          <div className="col-lg-8">
            <div className="blog-content">
              <div className="blog-item">
                <div className="blog-img">
                  <img className="img-fluid" src={blogImage} alt="" />
                  <span className="blog__date">12 April, 2022</span>
                </div>
                <div className="blog-inner-content">
                  <div className="inner-causes-box">
                    <h3 className="blog__title">
                      We thank your support for building a genuine and free
                      democratic future
                    </h3>
                    <ul className="blog__list">
                      <li className="blog__dot-active">
                        April 12, 2022 New York
                      </li>
                      <li className="blog__dot-active">Action for Democracy</li>
                      <li>Press release</li>
                    </ul>
                  </div>
                </div>
                <div className="blog-inner-content-2">
                  <p className="causes__text">
                    In the wake of the Hungarian elections, despite a heavy
                    heart we feel pride and gratitude for what we have built in
                    Action for Democracy. We thank our supporters for making it
                    possible.
                  </p>
                  <br />
                  <p className="causes__text">
                    We spent the past four months working with you to build a
                    global movement to support a democratic future in Hungary.
                    We built the largest diaspora get out the vote campaigns in
                    Hungarian history. We helped tens of thousands navigate a
                    registration process designed to disenfranchise Hungarian
                    voters abroad. We made sure they knew how, when, and where
                    to vote. We organized seven “Hello Spring” democracy
                    festivals across Europe bringing together a community of
                    committed Hungarians who care deeply about the democratic
                    future of their motherland. We deem our Hungarian Spring
                    diaspora mobilization campaign a success. The number of
                    Hungarians with both citizenship and residence who
                    registered to vote increased by 12% compared to 2018. 90% of
                    those who registered, showed up to vote.
                  </p>
                  <br />
                  <p className="causes__text">
                    We will continue to invest in campaigns and organizations
                    that are fighting to ensure all voices are heard in Hungary
                    with special regard to the Hungarian diaspora in Western
                    Europe, the US and Canada. Together we are committed to the
                    steady march toward building a genuine and free democratic
                    future.
                  </p>

                  <div className="news-tags"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledNewsItem>
  );
};

export default NewsSingle2;
